<template>
  <section class="lh-WrapperSingle lh-Versions">
    <ul class="lh-Breadcrumb">
      <li class="lh-Breadcrumb-item">
        <router-link class="lh-Breadcrumb-link" to="/">Accueil</router-link>
      </li>
      <li class="lh-Breadcrumb-item">
        <a class="lh-Breadcrumb-link" href="/versions/">Versions</a>
      </li>
    </ul>

    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--medium">
        <div
          class="lh-Versions-Container js-lh-Versions-Container"
          data-version="0"
        >
          <button
            @click="animatePlay(0)"
            aria-label="interagir avec la version 0"
            class="lh-Versions-Play"
            v-if="!isPlayed"
          >
            <svg class="lh-Versions-PlayIcon">
              <use xlink:href="#rotate"></use>
            </svg>
          </button>
          <button
            @click="animatePlay(0)"
            aria-label="interagir avec la version 0"
            class="lh-Versions-Play lh-Versions-Play--stop"
            v-else
          >
            <svg class="lh-Versions-PlayIcon">
              <use xlink:href="#stop"></use>
            </svg>
          </button>
          <p class="lh-Versions-Number js-lh-Versions-Number">0</p>
          <p class="js-lh-Versions-Desc lh-Versions-Desc">Dos de la main</p>
          <canvas
            id="js-lh-Versions-viewport"
            class="lh-Versions-viewport"
            :class="{ 'lh-Versions-viewport--disable': !isPlayed }"
          ></canvas>
        </div>
      </div>
      <p class="lh-Versions-Separator">&amp;</p>
      <div class="lh-Wrapper-box lh-Wrapper-box--medium lh-Wrapper-box--gray">
        <div
          class="lh-Versions-Container js-lh-Versions-Container"
          data-version="1"
        >
          <button
            @click="animatePlay(1)"
            aria-label="interagir avec la version 1"
            class="lh-Versions-Play"
            v-if="!isPlayed2"
          >
            <svg class="lh-Versions-PlayIcon">
              <use xlink:href="#rotate"></use>
            </svg>
          </button>
          <button
            @click="animatePlay(1)"
            aria-label="interagir avec la version 1"
            class="lh-Versions-Play lh-Versions-Play--stop"
            v-else
          >
            <svg class="lh-Versions-PlayIcon">
              <use xlink:href="#stop"></use>
            </svg>
          </button>
          <p class="lh-Versions-Number js-lh-Versions-Number">1</p>
          <p class="js-lh-Versions-Desc lh-Versions-Desc">Paume de face</p>
          <canvas
            id="js-lh-Versions-viewport2"
            class="lh-Versions-viewport"
            :class="{ 'lh-Versions-viewport--disable': !isPlayed2 }"
          ></canvas>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as Three from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { gsap } from "gsap";

export default {
  name: "version",
  data() {
    return {
      renderer: null,
      realCamera: null,
      fullLampeProduct: null,
      version: null,
      scene: null,
      controls: null,

      renderer2: null,
      realCamera2: null,
      fullLampeProduct2: null,
      version2: null,
      scene2: null,
      controls2: null,

      isPlayed: false,
      isPlayed2: false,
    };
  },

  methods: {
    init() {
      this.scene = new Three.Scene();
      this.fullLampeProduct = new Three.Group();
      this.renderer = new Three.WebGLRenderer({
        alpha: true,
        antialias: true,
        powerPreference: "high-performance",
        canvas: document.getElementById("js-lh-Versions-viewport"),
      });
      this.initSTL(0);
      this.initText(0);
      this.initRealCamera(0);

      this.renderer.setSize(
        document.querySelector(".js-lh-Versions-Container").offsetWidth,
        document.querySelector(".js-lh-Versions-Container").offsetHeight
      );
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = Three.PCFSoftShadowMap;
      this.scene.background = new Three.Color(0xffffff);

      this.scene2 = new Three.Scene();
      this.fullLampeProduct2 = new Three.Group();
      this.renderer2 = new Three.WebGLRenderer({
        alpha: true,
        antialias: true,
        powerPreference: "high-performance",
        canvas: document.getElementById("js-lh-Versions-viewport2"),
      });
      this.initSTL(1);
      this.initText(1);
      this.initRealCamera(1);

      this.renderer2.setSize(
        document.querySelector(".js-lh-Versions-Container").offsetWidth,
        document.querySelector(".js-lh-Versions-Container").offsetHeight
      );
      this.renderer2.setPixelRatio(window.devicePixelRatio);
      this.renderer2.shadowMap.enabled = true;
      this.renderer2.shadowMap.type = Three.PCFSoftShadowMap;
      this.scene2.background = new Three.Color(0xf5f5f5);
    },

    initRealCamera(version) {
      if (version == 0) {
        this.realCamera = new Three.PerspectiveCamera(
          75,
          document.querySelector(".js-lh-Versions-Container").offsetWidth /
            document.querySelector(".js-lh-Versions-Container").offsetHeight,
          0.01,
          21
        );
        this.realCamera.position.x = 14;
        this.realCamera.position.y = 6.5;
        this.realCamera.position.z = 0;
        this.realCamera.rotation.y = Math.PI / 2;

        this.controls = new OrbitControls(
          this.realCamera,
          this.renderer.domElement
        );
        this.controls.minDistance = 1;
        this.controls.maxDistance = 12;
        this.controls.target.set(0, 6.5, 0);

        this.realCamera.lookAt(0, 6.5, 0);
        this.realCamera.updateProjectionMatrix();
        this.scene.add(this.realCamera);
      } else {
        this.realCamera2 = new Three.PerspectiveCamera(
          75,
          document.querySelector(".js-lh-Versions-Container").offsetWidth /
            document.querySelector(".js-lh-Versions-Container").offsetHeight,
          0.01,
          21
        );
        this.realCamera2.position.x = 14;
        this.realCamera2.position.y = 6.5;
        this.realCamera2.position.z = 0;
        this.realCamera2.rotation.y = Math.PI / 2;

        this.controls2 = new OrbitControls(
          this.realCamera2,
          this.renderer2.domElement
        );
        this.controls2.minDistance = 1;
        this.controls2.maxDistance = 12;
        this.controls2.target.set(0, 6.5, 0);

        this.realCamera2.lookAt(0, 6.5, 0);
        this.realCamera2.updateProjectionMatrix();
        this.scene2.add(this.realCamera2);
      }
    },

    initText(version) {
      const material = new Three.MeshLambertMaterial({
        color: 0xffffff,
      });
      material.color.convertSRGBToLinear();
      const fontJSON = {
        glyphs: {
          0: {
            ha: 875,
            x_min: 49,
            x_max: 826,
            o: "m 438 -19 q 152 119 256 -19 q 49 486 49 257 q 152 853 49 715 q 438 992 256 992 q 724 853 621 992 q 826 486 826 715 q 724 119 826 257 q 438 -19 621 -19 m 290 249 q 315 209 290 249 q 438 168 340 168 q 585 250 535 168 q 636 486 636 332 q 585 724 636 642 q 438 806 535 806 q 290 724 340 806 q 240 486 240 642 q 290 249 240 331 z ",
          },
          1: {
            ha: 538,
            x_min: 15,
            x_max: 438,
            o: "m 271 972 l 438 972 l 438 0 l 246 0 l 246 769 l 63 718 l 15 882 l 271 972 z ",
          },
          " ": { ha: 347, x_min: 0, x_max: 0, o: "" },
          V: {
            ha: 899,
            x_min: 4,
            x_max: 896,
            o: "m 331 0 l 4 972 l 213 972 l 450 225 l 686 972 l 896 972 l 568 0 l 331 0 z ",
          },
          e: {
            ha: 807,
            x_min: 46,
            x_max: 764,
            o: "m 233 274 q 429 143 269 143 q 585 213 532 143 l 729 129 q 426 -19 626 -19 q 150 85 254 -19 q 46 347 46 189 q 149 609 46 504 q 413 714 251 714 q 665 608 565 714 q 764 347 764 503 q 757 274 764 313 l 233 274 m 231 413 l 585 413 q 522 518 569 483 q 413 553 474 553 q 293 516 340 553 q 231 413 246 479 z ",
          },
          i: {
            ha: 343,
            x_min: 61,
            x_max: 282,
            o: "m 171 778 q 94 810 126 778 q 61 888 61 843 q 94 965 61 932 q 171 999 126 999 q 249 965 217 999 q 282 888 282 932 q 249 810 282 843 q 171 778 217 778 m 82 0 l 82 694 l 261 694 l 261 0 l 82 0 z ",
          },
          n: {
            ha: 801,
            x_min: 82,
            x_max: 731,
            o: "m 468 714 q 656 638 582 714 q 731 426 731 561 l 731 0 l 551 0 l 551 404 q 514 510 551 474 q 414 547 476 547 q 303 504 344 547 q 261 375 261 461 l 261 0 l 82 0 l 82 694 l 261 694 l 261 617 q 468 714 326 714 z ",
          },
          o: {
            ha: 825,
            x_min: 46,
            x_max: 781,
            o: "m 674 87 q 620 34 674 87 q 413 -19 567 -19 q 152 87 258 -19 q 46 347 46 193 q 152 608 46 501 q 413 714 258 714 q 674 608 567 714 q 781 347 781 501 q 674 87 781 193 m 278 210 q 305 183 278 210 q 413 156 332 156 q 547 210 493 156 q 601 347 601 264 q 547 485 601 431 q 413 539 493 539 q 278 485 332 539 q 225 347 225 431 q 278 210 225 264 z ",
          },
          r: {
            ha: 494,
            x_min: 82,
            x_max: 472,
            o: "m 261 575 q 344 675 286 642 q 472 708 401 708 l 472 508 q 326 475 390 518 q 261 332 261 432 l 261 0 l 82 0 l 82 694 l 261 694 l 261 575 z ",
          },
          s: {
            ha: 646,
            x_min: 35,
            x_max: 606,
            o: "m 247 497 q 284 452 247 469 q 374 422 321 435 q 479 388 426 408 q 569 321 532 368 q 606 203 606 274 q 526 38 606 96 q 326 -19 446 -19 q 35 146 113 -19 l 190 233 q 326 143 221 143 q 422 203 422 143 q 385 248 422 231 q 296 279 349 265 q 190 314 243 293 q 101 380 138 335 q 64 493 64 425 q 140 655 64 596 q 328 714 215 714 q 482 676 413 714 q 592 567 551 638 l 439 483 q 328 554 406 554 q 270 539 293 554 q 247 497 247 524 z ",
          },
        },
        familyName: "Gilroy-Bold",
        ascender: 1074,
        descender: -315,
        underlinePosition: -243,
        underlineThickness: 69,
        boundingBox: { yMin: -365, xMin: -576, yMax: 1354, xMax: 1519 },
        resolution: 1000,
        original_font_information: {
          format: 0,
          copyright: "Copyright © 2016 by Radomir Tinkov. All rights reserved.",
          fontFamily: "Gilroy-Bold",
          fontSubfamily: "☞",
          uniqueID:
            "com.myfonts.easy.radomir-tinkov.gilroy.bold.wfkit2.version.4BUZ",
          fullName: "☞Gilroy-Bold",
          version:
            "Version 1.000;PS 001.000;hotconv 1.0.88;makeotf.lib2.5.64775;com.myfonts.easy.radomir-tinkov.gilroy.bold.wfkit2.version.4BUZ",
          postScriptName: "Gilroy-Bold",
          manufacturer: "Radomir Tinkov",
          designer: "Radomir Tinkov",
          manufacturerURL: "www.tinkov.info",
          designerURL: "www.tinkov.info",
        },
        cssFontWeight: "normal",
        cssFontStyle: "normal",
      };

      const versionGeometry = new Three.TextBufferGeometry(
        `Version ${version}`,
        {
          font: new Three.FontLoader().parse(fontJSON),
          size: 1.2,
          height: 0.2,
          material: 0,
          bevelThickness: 1,
          extrudeMaterial: 1,
        }
      );

      if (version == 0) {
        this.version = new Three.Mesh(versionGeometry, material);
        this.version.castShadow = true;
        this.version.rotation.y = Math.PI / 2;
        this.version.position.z = 3.6;
        this.version.position.y = 1.5;
        this.version.position.x = 3.2;

        this.scene.add(this.version);
      } else {
        this.version2 = new Three.Mesh(versionGeometry, material);
        this.version2.castShadow = true;
        this.version2.rotation.y = Math.PI / 2;
        this.version2.position.z = 3.6;
        this.version2.position.y = 1.5;
        this.version2.position.x = 3.2;

        this.scene2.add(this.version2);
      }
    },

    initSTL(version) {
      const that = this;

      var manager = new Three.LoadingManager();

      manager.onLoad = () => {
        that.isLoading = false;
        that.readyToAnim();
        that.animate();
      };

      // manager.onError = (url) => {
      //   console.log("There was an error loading " + url);
      // };

      var loader = new STLLoader(manager);
      var materialHands = new Three.MeshPhongMaterial({
        color: 0xf5f5f5,
        specular: 0xf0f0f0,
        shininess: 200,
        wireframe: true,
      });

      materialHands.color.convertSRGBToLinear();
      loader.load(
        `${process.env.BASE_URL}fullLampe${version}.stl`,
        (geometry) => {
          if (version == 0) {
            that.fullLampeProduct = new Three.Mesh(geometry, materialHands);
            that.fullLampeProduct.position.y = 1.5;
            that.fullLampeProduct.position.z = 0;
            that.fullLampeProduct.rotation.set(-Math.PI / 2, 0, Math.PI / 2);
            that.fullLampeProduct.scale.set(0.03, 0.03, 0.03);
          } else {
            that.fullLampeProduct2 = new Three.Mesh(geometry, materialHands);
            that.fullLampeProduct2.position.y = 1.5;
            that.fullLampeProduct2.position.z = 0;
            that.fullLampeProduct2.rotation.set(-Math.PI / 2, 0, Math.PI / 2);
            that.fullLampeProduct2.scale.set(0.03, 0.03, 0.03);
          }
        }
      );
      if (version == 0) {
        this.scene.add(new Three.HemisphereLight(0xffffff, 0x111122));
      } else {
        this.scene2.add(new Three.HemisphereLight(0xffffff, 0x111122));
      }
    },

    readyToAnim() {
      this.scene.add(this.fullLampeProduct);
      this.scene2.add(this.fullLampeProduct2);
    },

    animate() {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.realCamera);
      this.renderer2.render(this.scene2, this.realCamera2);
    },

    animatePlay(version = 0) {
      const timeline = new gsap.timeline();

      if (version == 0) {
        if (!this.isPlayed) {
          this.fullLampeProduct.rotation.set(-Math.PI / 2, 0, Math.PI / 2);
          timeline
            .to(
              this.fullLampeProduct.rotation,
              1,
              { z: this.fullLampeProduct.rotation.x + 3 * Math.PI },
              0
            )
            .to(
              this.realCamera.position,
              { duration: 1, x: 10, y: 6.5, z: 0 },
              0
            )
            .to(
              `
                .js-lh-Versions-Container[data-version="0"] .js-lh-Versions-Number,
                .js-lh-Versions-Container[data-version="0"] .js-lh-Versions-Desc
              `,
              1,
              { autoAlpha: 0.2 },
              0
            );

          this.isPlayed = true;
        } else {
          timeline
            .to(
              this.realCamera.position,
              { duration: 1, x: 14, y: 6.5, z: 0 },
              0
            )
            .to(this.realCamera.lookAt, { duration: 1, x: 0, y: 6.5, z: 0 }, 0)
            .to(
              this.realCamera.rotation,
              { duration: 1, x: 0, y: Math.PI / 2, z: 0 },
              0
            )
            .to(
              `
                .js-lh-Versions-Container[data-version="0"] .js-lh-Versions-Number,
                .js-lh-Versions-Container[data-version="0"] .js-lh-Versions-Desc`,
              1,
              { autoAlpha: 1 },
              0
            );
          this.isPlayed = false;
        }
      } else {
        this.fullLampeProduct2.rotation.set(-Math.PI / 2, 0, Math.PI / 2);
        if (!this.isPlayed2) {
          timeline
            .to(
              this.fullLampeProduct2.rotation,
              1,
              { z: this.fullLampeProduct.rotation.x + 3 * Math.PI },
              0
            )
            .to(
              this.realCamera2.position,
              { duration: 1, x: 10, y: 6.5, z: 0 },
              0
            )
            .to(
              `
                .js-lh-Versions-Container[data-version="1"] .js-lh-Versions-Number,
                .js-lh-Versions-Container[data-version="1"] .js-lh-Versions-Desc`,
              1,
              { autoAlpha: 0.2 },
              0
            );

          this.isPlayed2 = true;
        } else {
          timeline
            .to(
              this.realCamera2.position,
              { duration: 1, x: 14, y: 6.5, z: 0 },
              0
            )
            .to(this.realCamera2.lookAt, { duration: 1, x: 0, y: 6.5, z: 0 }, 0)
            .to(
              this.realCamera2.rotation,
              { duration: 1, x: 0, y: Math.PI / 2, z: 0 },
              0
            )
            .to(
              `
                .js-lh-Versions-Container[data-version="1"] .js-lh-Versions-Number,
                .js-lh-Versions-Container[data-version="1"] .js-lh-Versions-Desc`,
              1,
              { autoAlpha: 1 },
              0
            );

          this.isPlayed2 = false;
        }
      }
      timeline.play();
    },
  },

  mounted() {
    this.init();
  },

  destroyed() {
    this.renderer.dispose();
    this.renderer.forceContextLoss();
    this.renderer2.dispose();
    this.renderer2.forceContextLoss();
  },
};
</script>

<style lang="scss">
@import "@/components/versions/_index.scss";
</style>
