<template>
  <div>
    <Versions />
    <FAQContact is-boutique :is-contact="false" />
  </div>
</template>

<script>
import Versions from "@/components/versions/Versions.vue";
import FAQContact from "@/components/faqcontact/FAQContact.vue";

export default {
  name: "versions",
  components: {
    Versions,
    FAQContact,
  },
};
</script>
